<template>
<div class="wait-pay">
  <div class="top">
    <div class="left">
      <t-img src="pay-01.png" width="30rem" height="35rem"></t-img>
      <div class="time">
        <div>待支付金额</div>
        <div class="count red" v-if="!route.query.type">支付倒计时: <span>{{countDown.current.hours<10?'0'+countDown.current.hours:countDown.current.hours}}:{{countDown.current.minutes<10?'0'+countDown.current.minutes:countDown.current.minutes}}:{{countDown.current.seconds<10?'0'+countDown.current.seconds:countDown.current.seconds}}</span></div>
      </div>
    </div>
    <div class="money red">
      {{'￥'+tools.formatMoney(RMB/100)}}
    </div>
  </div>
  <div class="content">
    <t-order class="order-card" :data="c1"></t-order>
    <t-order class="order-card" :data="c2"></t-order>
    <t-order class="order-card" :data="c3"></t-order>
    <div class="wait-box" v-if="offline">
      <img class="img my-1" src="@/assets/imgs/zp-14.png" alt="等待" />
      <div class="text">财务正在飞速审核中，请耐心等待~~</div>
    </div>
  </div>
  <div class="footer">
    <t-button class="cancel" @click="cancelOrder">取消订单</t-button>
    <view class="ml-2" v-if="offline === 0">
      <t-button class="pay" type="primary" @click="handlePay"> 立即支付</t-button>
    </view>
  </div>

  <form ref="form" method="post" action="" style="display:none;" >
    <input name="json" />
    <input name="appkey" />
    <input name="signature" />
    <input type="submit" id="hidden_submit" value="">
  </form>
  <van-popup v-model:show="payType" position="bottom">
    <div class="pay-type">
      <div class="row" v-for="(val,k) in paymentConfig" :style="{color:payment[k].color}" @click="onPay(k)">
        <van-icon class="icon" v-if="!payment[k].iconUrl" :name="payment[k].icon"></van-icon>
        <t-img class="icon-img" :src="payment[k].iconUrl" height="20rem" width="26rem" v-else/>
        <span class="name">{{ payment[k].title }}</span>
      </div>
    </div>
  </van-popup>
</div>
</template>

<script setup>
import {useCountDown} from "@vant/use";
import tools from "../../utils/tools.js";
import {onMounted, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import axios from "../../axios/index.js";
import {useStore} from "vuex";
import {getCurPathInfo, showLoading} from "../../utils/utils.js";
import {Dialog, Toast} from "vant";
import {getBaseConfig} from "@/utils/api.js";
import {onHistoryBack} from "../../utils/recordRouter.js";
import {defaultOrderPay} from "../../utils/api.js";
const route = useRoute();
const router = useRouter();
const store = useStore();
const form = ref();
const countDown = ref(useCountDown({
  time:30 *60 * 1000
}))
const payType = ref(false);
const type = Number.isNaN(Number(route.query.type))? 2 : Number(route.query.type);
// 通过安心付返回
const axf = route.query.axf;
const RMB = ref(0);
const orderNumber = route.query.order_number;
const paymentConfig = ref({});
const data = ref(null);
let axfTimer = null;
onHistoryBack((self)=>{
  console.log("禁止返回上一页")
},false);
getBaseConfig().then(res=>{
  store.commit("setBaseConfig",res)
  paymentConfig.value = res.payment_method
})
// 支付方式关系映射
const payment = {
  UnionPay: {
    name: "UnionPay",
    title: "云闪付",
    iconUrl: "yunshanfu.png",
    color: "#e50012"
  },
  Alipay: {
    name: "Alipay",
    title: "支付宝",
    icon: "alipay",
    color: "rgb(0, 159, 233)"
  },
  WXPay: {
    name: "WXPay",
    title: "微信支付（推荐）",
    icon: "wechat-pay",
    color: "rgb(105, 187, 100)"
  },
}
const offline = ref(false);
const payMethod = ref(null);
const c1 = ref({
  "服务名称":'',
  "报名学校":'',
})
const c2 = ref({
  "客户姓名":'',
  "报名手机号":'',
  "学生姓名":'',
  "咨询老师":'',
  "咨询老师电话":'',
})
const c3 = ref({})
const handlePay = () => {
  // payType.value = true
  showLoading(true,"请稍后...");
  if(payMethod.value === "anxinfu"){
    if (data.value.money === 0) {
      defaultOrderPay({
        order:orderNumber,
        payer:1,
        payment_method:"Alipay",
        order_number:orderNumber
      },{
        order:orderNumber,
        payment_method:"Alipay",
        payer:1
      }).finally(()=>showLoading(false))
      return;
    }
    let url = axios.defaults.baseURL + "/api/recruit-2024/offline/jump-h5?jump="+ encodeURIComponent(location.href+"&axf=1");
    axios.post("api/recruit-2024/offline/anxinfu",{
      order_id:data.value.id,
      callback:url
    }).then(res=>{
      let {url,json,appkey,signature} = res;
      let f = form.value;
      f.action = url;
      f.querySelector("input[name='json']").value = json;
      f.querySelector("input[name='appkey']").value = appkey;
      f.querySelector("input[name='signature']").value = signature;
      f.querySelector("#hidden_submit").click();
    }).finally(()=>showLoading(false))
  }
  else router.push(`/zb/Payment/method?order=${orderNumber}&type=`+type).then(res=>showLoading(false))
}
/**
 * @desc 去支付
 * @param k 支付方式映射的key
 * */
function onPay(k){
  let pm = payment[k];
  showLoading(true);
  axios.post("/api/recruit/family/pay",{
    order_number:orderNumber,
    payment_method:pm.name
  }).then(res=>{
    showLoading(false);
    payType.value = false;
    if (res.code === 1) {
      router.push("/order-wait?order_number="+orderNumber+"&order_m=" + res.order_m + "&pay_url="+encodeURIComponent(res.url))
    } else {
      router.push("/order-wait?order_number="+orderNumber+"&order_m=" + res.order_m)
    }
  }).catch(err=>{
    showLoading(false);
  })
}
function cancelOrder(){
  Dialog.confirm({
    message:"确定取消订单?",
    confirmButtonColor:"#417FF7",
    cancelButtonText:"点错了",
  }).then(res=>{
    showLoading(true)
    axios.get("/api/recruit/family/cancel",{
      params:{
        order_number:orderNumber
      }
    }).then(res=>{
      showLoading(false);
      if (axfTimer)clearTimeout(axfTimer);
      Toast({message:"订单已取消"});
      // router.back();
      router.replace({path:"/order-status",query:{
        status:2
        }})
    }).catch(res=>showLoading(false))
  })

}

function getData(init = false){
  // 获取初始数据
  return axios.post("/api/recruit/family/order-list",{
    order_number:orderNumber,
    t:Date.now()
  }).then(res=>{
    data.value = res;
    if (!init)return res;
    // 状态 0初始状态 1已支付 2取消支付（超时系统取消） 3已退款  4取消支付（家长手动取消）5支付失败 6已生成 7已取消（审批驳回）
    // offline 1 表示线下支付订单 0. 其他订单
    // offline_pay_status  101 待审批 102 已通过 103 已驳回
    // 处于待支付状态外 返回列表
    if(res.status && axf == null) return router.back()
    let ct= new Date(res.created_at);
    let now = new Date();
    c1.value.服务名称 = res.service_name;
    c1.value.报名学校 = res.school_name;
    c2.value.客户姓名 = res.customer_name;
    c2.value.报名手机号 = res.customer_phone;
    c2.value.学生姓名 = res.student_name;
    c2.value.咨询老师 = res.consulting_teacher_name || '-';
    c2.value.咨询老师电话 = res.consulting_teacher_phone || '-';
    const obj = {
      "支付方式": '线下支付',
      "商品订单号": res.order_number,
      "订单创建时间": ct.Format("yyyy-MM-dd hh:mm:ss")
    }
    RMB.value = res.money;
    offline.value = res.offline;
    payMethod.value = res.pay_method;
    if(offline.value !== 1){
      delete obj['支付方式']
    }
    c3.value = obj
    countDown.value.reset(ct.getTime() + 60 * 1000 * 30 - now.getTime());
    countDown.value.start();
    return res;
  })
}
// 安心付 刷新
function axfRefresh(init = false){
  getData(init).then(res=> {
    console.log(res);
    if (res.status === 1) {
      router.push(getCurPathInfo().order);
    } else
      axfTimer = setTimeout(axfRefresh, 3000);
  })
}
onMounted(()=>{
  if (axf != null){
    showLoading(true,"请稍后...")
   setTimeout(()=> {
     showLoading(false)
     axfRefresh(true)
   },1000);
  }else getData(true);
})

</script>

<style lang="scss" scoped>
.wait-pay{
  .red{
    color:#F74156;
  }
  $p:10px;
  min-height: 100vh;
  background-color: #F8F8F8;
  position: relative;
  .top{
    height: 86rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30rem;
    .left{
      display: flex;
      align-items: center;
      .time{
        margin-left: 8rem;
        .count{
          font-size: 12rem;
          span{
            margin-left: 8rem;
          }
        }
      }
    }
    .money{
      font-size: 16rem;
      font-weight: 600;
    }
  }
  .content{
    padding: 18rem 14rem 140rem;
    .order-card{
      margin-bottom: 12rem;
      padding-top: 10rem;
      padding-bottom: 10rem;
    }
  }
  .footer{
    position: fixed;
    background-color: white;
    bottom: 0;
    height: 60rem;
    width: calc(100% - 36rem);
    padding: 18rem;
    display: flex;
    justify-content: flex-end;
    .cancel{
      width: 105rem;
      color:#787878;
    }
    .pay{
      width:200rem;
    }
  }
  .pay-type{
    padding: 10rem 0 25rem;
    text-align: center;
    .row:last-child{
      border-bottom: none  ;
    }
    .row{
      padding: 25rem 10rem;
      border-bottom: 1px solid #eee;
      font-size: 16rem;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
      .icon{
        font-size: 20rem;
        margin-right: 10rem;
      }
      .icon-img{
        width: 26rem;
        height: 20rem;
        margin-right: 10rem;
      }
    }
  }
}
.wait-box {
  text-align: center;
  .img {
    width: 24rem;
    height: 24rem;
  }
  .text {
    color: #333;
  }
}
</style>
